import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import {
  Loading,
  Message,
  Table as CustomTable,
} from '../../../components/elements';

import client from '../../../utils/apolloClient';
import List from './List';
import FormModal from './FormModal';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const searchOverrideOrderFeeQuery = gql`
  query searchOverrideOrder($input: SearchInput) {
    search_order_fee_override(input: $input) {
      order_fee_override_listing {
        order_fee {
          fee_id
          is_payment_gateway_fee
          is_kravein_charge
          name
          display
          sale_method
          applies_to
          type
          value
          status
          tax
          tax_description
          tax_id
          tax_value
        }
        order_fee_override {
          fee_id
          display
          value
          override
          minimum_order_amount
        }
      }
    }
  }
`;

const createFeeMutation = gql`
  mutation createFee($input: OrderFeeOverrideInput) {
    create_order_fee_override(input: $input) {
      fee_id
      error {
        description
      }
    }
  }
`;

const updateFeeMutation = gql`
  mutation updateFee($input: OrderFeeOverrideInput) {
    update_order_fee_override(input: $input) {
      fee_id
      error {
        description
      }
    }
  }
`;

const deleteFeeMutation = gql`
  mutation deleteFee($input: OrderFeeOverrideInput) {
    delete_order_fee_override(input: $input) {
      fee_id
      error {
        description
      }
    }
  }
`;

const Details = ({ serviceType, serviceTypeSettingId, placeId }) => {
  const { userId } = useStoreState(state => state.auth);
  const [fee, setFee] = useState(null);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const { data, loading, error, refetch } = useQuery(
    searchOverrideOrderFeeQuery,
    {
      variables: {
        input: {
          filter: {
            order_fee_filter: { place_id: placeId, service_type: serviceType },
          },
        },
      },
      client: client.clientPrivate,
    },
  );

  const [createFee, { loading: loading3 }] = useMutation(createFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ create_order_fee_override }) => {
      if (!isNull(create_order_fee_override.error)) {
        create_order_fee_override.error.map(item =>
          toast.error(item.description),
        );
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  const [updateFee, { loading: loading2 }] = useMutation(updateFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_order_fee_override }) => {
      if (!isNull(update_order_fee_override.error)) {
        update_order_fee_override.error.map(item =>
          toast.error(item.description),
        );
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  const [deleteFee, { loading: loading4 }] = useMutation(deleteFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ delete_order_fee_override }) => {
      if (!isNull(delete_order_fee_override.error)) {
        delete_order_fee_override.error.map(item =>
          toast.error(item.description),
        );
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  return (
    <div>
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>Fee Name</Table.Heading>
                <Table.Heading>Fee Display Label</Table.Heading>
                <Table.Heading>Sale Method</Table.Heading>
                <Table.Heading>Fee Type</Table.Heading>
                <Table.Heading>Fee</Table.Heading>
                <Table.Heading>Tax Type</Table.Heading>
                <Table.Heading>Tax</Table.Heading>
                <Table.Heading>Is Gateway Fee</Table.Heading>
                <Table.Heading>Is Kravein Fee</Table.Heading>
                <Table.Heading>Min Order Amt</Table.Heading>
                <Table.Heading>Applies To</Table.Heading>
                <Table.Heading>Action</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                loading={loading4}
                fees={data.search_order_fee_override.order_fee_override_listing}
                onResetToDefault={value => {
                  swal(
                    'Are you sure you want to reset override fee to default?',
                    {
                      buttons: ['Cancel', true],
                    },
                  ).then(willDelete => {
                    if (willDelete) {
                      deleteFee({
                        variables: {
                          input: {
                            user_id: userId,
                            fee_id: value.fee_id,
                            service_type_setting_id: serviceTypeSettingId,
                            service_type: serviceType,
                          },
                        },
                      });
                    }
                  });
                }}
                onHandleOverride={value => {
                  setFee(value);
                  setIsActiveModal(true);
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      )}
      {isActiveModal && (
        <FormModal
          fee={fee}
          isActive={isActiveModal}
          loading={loading2 || loading3}
          onClose={() => setIsActiveModal(false)}
          onSubmit={values => {
            if (fee.order_fee_override && fee.order_fee_override.override) {
              updateFee({
                variables: {
                  input: {
                    user_id: userId,
                    fee_id: fee.order_fee.fee_id,
                    value: values.value,
                    override: true,
                    minimum_order_amount: values.minimum_order_amount,
                    service_type: serviceType,
                    service_type_setting_id: serviceTypeSettingId,
                    display: values.display,
                  },
                },
              }).then(() => {
                refetch();
              });
            } else {
              createFee({
                variables: {
                  input: {
                    user_id: userId,
                    fee_id: fee.order_fee.fee_id,
                    value: values.value,
                    override: true,
                    minimum_order_amount: values.minimum_order_amount,
                    service_type: serviceType,
                    service_type_setting_id: serviceTypeSettingId,
                    display: values.display,
                  },
                },
              }).then(() => {
                refetch();
              });
            }
          }}
        />
      )}
    </div>
  );
};

export default Details;
