/* eslint-disable max-len */
const parseBusinessHours = businessHour => {
  if (Array.isArray(businessHour)) {
    let mondayHours = businessHour[0];
    mondayHours = mondayHours.is_active
      ? mondayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';
    let tuesdayHours = businessHour[1];
    tuesdayHours = tuesdayHours.is_active
      ? tuesdayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';

    let wednesdayHours = businessHour[2];
    wednesdayHours = wednesdayHours.is_active
      ? wednesdayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';

    let thursdayHours = businessHour[3];
    thursdayHours = thursdayHours.is_active
      ? thursdayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';

    let fridayHours = businessHour[4];
    fridayHours = fridayHours.is_active
      ? fridayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';

    let saturdayHours = businessHour[5];
    saturdayHours = saturdayHours.is_active
      ? saturdayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';

    let sundayHours = businessHour[6];
    sundayHours = sundayHours.is_active
      ? sundayHours.option
          .map(ele => `${ele.start}&nbsp;-&nbsp;${ele.end}`)
          .join('<br>')
      : 'Closed';

    return {
      mondayHours,
      tuesdayHours,
      wednesdayHours,
      thursdayHours,
      fridayHours,
      saturdayHours,
      sundayHours,
    };
  }
  return {
    mondayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
    tuesdayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
    wednesdayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
    thursdayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
    fridayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
    saturdayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
    sundayHours: '11:00 am&nbsp;-&nbsp;09:00 pm',
  };
};

const parseFormInputValues = formInputs => {
  const {
    description,
    name,
    city,
    state,
    image_url,
    foodPickupURL,
    foodDeliveryURL,
    business_hour,
    latitude,
    longitude,
    address_line_1,
    post_code,
    primaryMobile,
    food_delivery_description,
    zone_1_amount,
    zone_1_fee,
    zone_2_amount,
    zone_2_fee,
    zone_3_amount,
    zone_3_fee,
    footer_text,
    image,
    logo_url,
    bookingURL,
  } = formInputs;
  return {
    logo_url,
    description,
    name,
    city,
    state,
    image_url,
    foodPickupURL,
    foodDeliveryURL,
    foodDeliveryDescription: food_delivery_description,
    ...parseBusinessHours(business_hour),
    lat: latitude,
    long: longitude,
    address: `${address_line_1}, ${city}, ${state}, ${post_code}`,
    phoneNumber: primaryMobile,
    zone_1_amount: parseFloat(zone_1_amount || 0).toFixed(2),
    zone_1_fee: parseFloat(zone_1_fee || 0).toFixed(2),
    zone_2_amount: parseFloat(zone_2_amount || 0).toFixed(2),
    zone_2_fee: parseFloat(zone_2_fee || 0).toFixed(2),
    zone_3_amount: parseFloat(zone_3_amount || 0).toFixed(2),
    zone_3_fee: parseFloat(zone_3_fee || 0).toFixed(2),
    footer_text,
    images:
      Array.isArray(image) && image.length > 1
        ? image
            .filter(ele => ele.preview)
            .map(ele => ele.url)
            .map(
              url =>
                `<img src=${url} alt=${url} width="200" height="200" loading="lazy">`,
            )
            .join('\n')
        : null,
    bookingURL,
  };
};

const dataFunc = ({
  description,
  name,
  city,
  state,
  image_url,
  foodPickupURL,
  foodDeliveryURL,
  foodDeliveryDescription,
  mondayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  tuesdayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  wednesdayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  thursdayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  fridayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  saturdayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  sundayHours = '11:00 am&nbsp;-&nbsp;09:00 pm',
  lat,
  long,
  address,
  phoneNumber,
  zone_1_amount,
  zone_1_fee,
  zone_2_amount,
  zone_2_fee,
  zone_3_amount,
  zone_3_fee,
  footer_text,
  images,
  logo_url,
  bookingURL,
}) => ({
  bookingURL,
  logoUrl: logo_url,
  metaDescription: `Order Online for Takeaway / Delivery. ${description}`,
  titleOfWebPage: `${name} | ${city} | ${state} | Order Online`,
  completeUrlOfHeroImage: image_url,
  nameOfRestaraunt: `${name}`,
  foodPickupHref: foodPickupURL,
  foodDeliveryHref: foodDeliveryURL,
  descriptionOfRestaraunt: description,
  aboutUsSection: `<section class="center-text">
      <h2 id="about-us">About ${name}</h2>
      <hr />
      <p></p>
      <p>
      ${foodDeliveryDescription}
       <div>
            <p style="font-weight: bold">Contact us at</p>
            <p>${address}.</p>
            <p>${phoneNumber}</p>
      </div>
      </section>`,
  openingHoursSection: `<section class="opening-hours-container">
      <svg aria-hidden="true"><use xlink:href="#icon-hang"></use></svg>
      <div class="opening-hours">
        <div
          class="wood-background"
          lazy-style="background-image: url(&#39;/content/img/wood2.png&#39;)"
          style="background-image: url(&#39;/content/img/wood2.png&#39;)"
        ></div>
        <div class="wood-border-1"></div>
        <div class="wood-border-2"></div>
        <div class="content">
          <h2 id="opening-hours" style="margin-bottom: 24px">
            Opening hours
          </h2>
          <div class="entry">
            <div>Monday&nbsp;</div>
            <div>${mondayHours}
            </div>
               
          </div>
          <div class="entry">
            <div>Tuesday&nbsp;</div>
            <div>${tuesdayHours}
            </div>
          </div>
         <div class="entry">
            <div>Wednesday&nbsp;</div>
            <div>${wednesdayHours} 
            </div>
               
          </div>
           <div class="entry">
            <div>Thursday&nbsp;</div>
            <div>${thursdayHours}
            </div>
               
          </div>
       <div class="entry">
            <div>Friday&nbsp;</div>
            <div>${fridayHours}
            </div>
               
          </div>
           <div class="entry">
            <div>Saturday&nbsp;</div>
            <div>${saturdayHours}
            </div>
               
          </div>
           
          <div class="entry">
            <div>Sunday&nbsp;</div>
    <div>${sundayHours}
            </div>
               
          </div>
        </div>
      </div>
    </section>`,
  footerExtraLinks: [footer_text],
  lat,
  long,
  infoClass: `
 <div class="info">
          <div class="info-inner">
            <h2>Food delivery in ${city}</h2>
            <hr />
            <p></p>
            <p>
              Looking for food delivery in ${city}? Not everybody knows or has
              the time to prepare tasty food.
            </p>
            <p>
              When you want to get served like a king then food delivery from
              ${name} will be your best choice.
            </p>
            <p>
              Simply select "Delivery" at the checkout screen and we hope you'll
              appreciate our food delivery service.
            </p>
            <p></p>
            <h3>Delivery fee</h3>
            <div class="entry">
              <span class="zone-color" style="background-color: #fc743a"></span>
              <strong>Zone 1</strong>, Min - $${zone_1_amount}, Fee - $${zone_1_fee}
            </div>
            <div class="entry">
              <span class="zone-color" style="background-color: #3eb09b"></span>
              <strong>Zone 2</strong>, Min - $${zone_2_amount}, Fee - $${zone_2_fee}
            </div>
            <div class="entry">
              <span class="zone-color" style="background-color: #f2ac1f"></span>
              <strong>Zone 3</strong>, Min - $${zone_3_amount}, Fee - $${zone_3_fee}
            </div>
          </div>
        </div>
    `,

  imageGallery: images
    ? `
     <section class="center-text">
        <h2>Image Gallery</h2>
        <br>
        <div style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 16px;
            padding: 16px;
          ">

          ${images}
        </div>
      </section>
    `
    : undefined,
});

const getTemplateData = formInputs =>
  dataFunc(parseFormInputValues(formInputs));

export default getTemplateData;
