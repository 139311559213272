import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Details from './Details';
import client from '../../../utils/apolloClient';
import { Loading, Message } from '../../../components/elements';

const searchDefaultOrderFeeQuery = gql`
  query searchDefaultOrderFee($input: SearchInput, $userId: String) {
    search_order_fee_default(input: $input) {
      order_fee_default_listing {
        service_type_id
        name
        order_fee {
          is_kravein_charge
          fee_id
          name
          display
          is_payment_gateway_fee
          sale_method
          applies_to
          type
          value
          status
          tax
          tax_description
          tax_id
          tax_value
        }
      }
    }
    fetch_tax(input: { user_id: $userId }) {
      tax_id
      status
      name
      value
    }
  }
`;

const DefaultFoodOrderFee = () => {
  const { userId } = useStoreState(state => state.auth);

  const { data, loading, error, refetch } = useQuery(
    searchDefaultOrderFeeQuery,
    {
      variables: {
        input: {
          sort: 'SERVICE_TYPE_ASC',
          filter: {},
        },
        userId,
      },
      client: client.clientPrivate,
    },
  );

  // { label: 'ONLINE', value: 'ONLINE' },
  // { label: 'OFFLINE', value: 'OFFLINE' },

  return (
    <Layout>
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Details
          fees={data.search_order_fee_default.order_fee_default_listing}
          taxesOptions={data.fetch_tax.map(ele => ({
            label: ele.name,
            value: ele.name,
          }))}
          refetch={refetch}
        />
      )}
    </Layout>
  );
};

export default DefaultFoodOrderFee;
