import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

import { Table as CustomTable, Box } from '../../../components/elements';

import Header from './Header';
import List from './List';
import AddEditForm from './AddEditForm';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const createTaxMutation = gql`
  mutation createTaxMutation($input: TaxListingInput) {
    create_tax(input: $input) {
      tax_id
      value
      status
      name
      error {
        description
      }
    }
  }
`;

const updateTaxMutation = gql`
  mutation updateTaxMutation($input: TaxListingInput) {
    update_tax(input: $input) {
      tax_id
      value
      status
      name
      error {
        description
      }
    }
  }
`;

const Details = ({ taxValues, refetch }) => {
  const { userId } = useStoreState(state => state.auth);
  const [tax, setTax] = useState(null);
  const [isActiveModal, setIsActiveModal] = useState(false);

  const taxes = (taxValues ?? []).map(item => ({
    name: item.name,
    value: item.value,
    id: item.tax_id,
  }));

  const [createTax, { loading }] = useMutation(createTaxMutation, {
    client: client.clientPrivate,
    onCompleted: ({ create_tax }) => {
      if (!isNil(create_tax.error)) {
        create_tax.error.map(item => toast.error(item.description));
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  const [updateTax, { loading: loading2 }] = useMutation(updateTaxMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_tax }) => {
      if (!isNil(update_tax.error)) {
        update_tax.error.map(item => toast.error(item.description));
      } else {
        setIsActiveModal(false);
        refetch();
        setTax(null);
      }
    },
  });

  return (
    <React.Fragment>
      <Header setFee={setTax} setIsActiveModal={setIsActiveModal} />
      <Box>
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>Tax</Table.Heading>
                <Table.Heading>Description</Table.Heading>
                <Table.Heading>Value</Table.Heading>
                <Table.Heading>Actions</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                taxes={taxes}
                onEdit={value => {
                  setTax(value);
                  setIsActiveModal(true);
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          tax={tax}
          loading={loading || loading2}
          isActive={isActiveModal}
          onClose={() => setIsActiveModal(false)}
          onSubmit={values => {
            if (tax) {
              updateTax({
                variables: {
                  input: {
                    user_id: userId,
                    status: 'ACTIVE',
                    name: values.name,
                    value: values.value,
                  },
                },
              });
            } else {
              createTax({
                variables: {
                  input: {
                    user_id: userId,
                    status: 'ACTIVE',
                    name: values.name,
                    value: values.value,
                  },
                },
              });
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Details;
