import React from 'react';

import { Table, Button, Icon } from 'rbx';

import styled from 'styled-components';

const Element = styled.div``;

const protectedTaxes = new Set(['DFT', 'GST', 'WAT']);

const protectedTaxesDescription = {
  DFT: 'Delivery Fee tax',
  GST: 'Goods and Services Tax',
  WAT: '',
};

const List = ({ taxes, onEdit, onDelete = () => {} }) => (
  <>
    {taxes.map(item => (
      <Table.Row>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          {protectedTaxes.has(item.name)
            ? protectedTaxesDescription[item.name]
            : item.name}
        </Table.Cell>
        <Table.Cell>${parseFloat(item.value).toFixed(2)}</Table.Cell>
        <Table.Cell>
          <Element>
            <Button.Group hasAddons>
              <Button onClick={() => onEdit(item)}>
                <Icon size="small">
                  <i className="fas fa-edit" />
                </Icon>
              </Button>
              {!protectedTaxes.has(item.name) && (
                <Button onClick={() => onDelete(item)}>
                  <Icon size="small">
                    <i className="fas fa-trash has-text-grey" />
                  </Icon>
                </Button>
              )}
            </Button.Group>
          </Element>
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
